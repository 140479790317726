import React, { useState } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	TextField,
	FormControl,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
	InputLabel,
	Select,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	Button,
	List,
	ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axiosInstance from "../../utils/axios";

// All campaigns (for reference at the bottom)
const allCampaigns = [
	{
		title: "The Importance of Annual Bloodwork",
		description:
			"Early detection of hidden conditions leads to better treatment outcomes.",
	},
	{
		title: "General Wellness — Getting Back to Basics",
		description:
			"Promote annual exams and vaccines, focusing on core prevention measures.",
	},
	{
		title: "Fecal Testing and Zoonotic Risks",
		description:
			"Highlight parasite dangers and the importance of regular fecal checks.",
	},
	{
		title: "Itchy Pets — What Could It Mean?",
		description:
			"Educate clients on potential causes (allergies, parasites) and available relief.",
	},
	{
		title: "Osteoarthritis — Is Your Pet in Pain?",
		description:
			"Discuss OA in older pets, pain management, and quality-of-life improvements.",
	},
	{
		title:
			"Product-Based Promotion — Simp Trio / ProHeart 12 / Revolution Plus",
		description:
			"Encourage year-round prevention and simplify compliance for pet owners.",
	},
	{
		title: "Year-Round Parasite Protection",
		description:
			"Emphasize continuous prevention for fleas, ticks, and heartworms year-round. YOU MUST CARRY SIMPARICA TRIO, PROHEART 12, AND/OR REVOLUTION PLUS IN ORDER TO UTILIZE THIS CAMPAIGN.",
	},
	{
		title: "Bonqat — Making Vet Visits Easier for Cats",
		description:
			"Introduce a stress-reducing solution to improve feline clinic visits.",
	},
	{
		title: "Lifestyle Vaccines — What Are Your Pets at Risk For?",
		description:
			"Discuss Bordetella, CIV, Lepto, Lyme, and other risk-based vaccine options.YOU MUST CARRY AT LEAST ONE ZOETIS-BRAND VACCINE TO UTILIZE THIS CAMPAIGN.  ",
	},
];

// Campaigns that appear in K9 & Feline dropdowns
const k9CampaignOptions = [
	"General Wellness",
	"Annual Bloodwork",
	"Osteoarthritis",
	"Itchy Pets",
	"Lifestyle Vaccines",
	"Fecal Testing",
	"Year-round Prevention",
	"Simparica Trio",
	"ProHeart 12",
];

const felCampaignOptions = [
	"General Wellness",
	"Annual Bloodwork",
	"Osteoarthritis",
	"Itchy Pets",
	"Lifestyle Vaccines",
	"Fecal Testing",
	"Year-round Prevention",
	"Revolution Plus",
	"Bonqat",
];

// Helper to create a blank campaign object
const createCampaign = () => ({
	k9Version: "",
	felVersion: "",
	launchMonth: null,
	halfOfMonth: "1st",
	specialMessaging: false,
});

export default function CampaignForm() {
	const [practiceName, setPracticeName] = useState("");
	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactPhone, setContactPhone] = useState("");
	const [pimsChanged, setPimsChanged] = useState("No");
	const [pimsSystem, setPimsSystem] = useState("");
	const [otherChanges, setOtherChanges] = useState("");

	// Start with one blank campaign
	const [campaigns, setCampaigns] = useState([createCampaign()]);

	// Add a new campaign slot (up to 4)
	const handleAddCampaign = () => {
		if (campaigns.length >= 4) {
			alert("You can only choose up to 4 campaigns.");
			return;
		}
		setCampaigns([...campaigns, createCampaign()]);
	};

	// Remove a campaign slot
	const handleRemoveCampaign = (index) => {
		const updated = [...campaigns];
		updated.splice(index, 1);
		setCampaigns(updated);
	};

	// Update a campaign field by index
	const handleCampaignChange = (index, field, value) => {
		const updatedCampaigns = [...campaigns];
		updatedCampaigns[index][field] = value;
		setCampaigns(updatedCampaigns);
	};

	const handleSubmit = async () => {
		const formData = {
			practiceName,
			contactName,
			contactEmail,
			contactPhone,
			pimsChanged,
			pimsSystem,
			otherChanges,
			campaigns,
		};

		try {
			const response = await axiosInstance.post(
				"/campaign/2025/re-register-submission",
				formData
			);
			console.log("Successfully saved form data:", response.data);
			alert("Form submitted successfully!");
		} catch (error) {
			console.error("Error submitting form:", error);
			alert("An error occurred while submitting the form.");
		}
	};

	return (
		<Box sx={{ backgroundColor: "#f7f7f7", minHeight: "100vh", py: 4 }}>
			<Container maxWidth="md">
				{/* Header / Hero Section */}
				<Box
					sx={{
						backgroundColor: "primary.main",
						color: "#fff",
						borderRadius: 2,
						p: 4,
						mb: 4,
						textAlign: "center",
					}}
				>
					<Typography variant="h3" gutterBottom>
						Register for Your 2025 Campaigns
					</Typography>
					<Typography variant="subtitle1">
						Keep your clinic’s marketing on track all year long. Select up to
						four email campaigns to engage clients on essential pet health
						topics.
					</Typography>
				</Box>

				{/* Practice Information */}
				<Box
					sx={{
						backgroundColor: "#fff",
						p: { xs: 2, md: 4 },
						borderRadius: 2,
						boxShadow: 2,
						mb: 4,
					}}
				>
					<Typography variant="h5" gutterBottom>
						Practice Information
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="Practice Name"
								value={practiceName}
								onChange={(e) => setPracticeName(e.target.value)}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="Contact Name"
								value={contactName}
								onChange={(e) => setContactName(e.target.value)}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="Contact Email"
								value={contactEmail}
								onChange={(e) => setContactEmail(e.target.value)}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								fullWidth
								label="Contact Phone"
								value={contactPhone}
								onChange={(e) => setContactPhone(e.target.value)}
								margin="normal"
							/>
						</Grid>
					</Grid>

					<Box mt={2}>
						<FormControl component="fieldset" margin="normal">
							<FormLabel>
								Have you changed your PIMS system in the past 12 months?
							</FormLabel>
							<RadioGroup
								row
								value={pimsChanged}
								onChange={(e) => setPimsChanged(e.target.value)}
							>
								<FormControlLabel value="No" control={<Radio />} label="No" />
								<FormControlLabel value="Yes" control={<Radio />} label="Yes" />
							</RadioGroup>
						</FormControl>
					</Box>
					{pimsChanged === "Yes" && (
						<TextField
							fullWidth
							label="If yes, to what system?"
							value={pimsSystem}
							onChange={(e) => setPimsSystem(e.target.value)}
							margin="normal"
						/>
					)}

					<TextField
						fullWidth
						label="Any other changes? (moved location, updated logo, etc.)"
						value={otherChanges}
						onChange={(e) => setOtherChanges(e.target.value)}
						margin="normal"
						multiline
					/>
				</Box>

				{/* Campaign Selections */}
				<Box
					sx={{
						backgroundColor: "#fff",
						p: { xs: 2, md: 4 },
						borderRadius: 2,
						boxShadow: 2,
						mb: 4,
					}}
				>
					<Typography variant="h5" gutterBottom>
						Choose up to 4 campaigns
					</Typography>
					<Typography variant="body2" gutterBottom>
						Select which campaigns you’d like to run. We recommend spacing them
						out quarterly so clients remain engaged year-round.
					</Typography>

					<List dense sx={{ mt: 2 }}>
						{allCampaigns.map((campaign) => (
							<ListItem key={campaign.title} disableGutters>
								<Typography variant="body2">
									<strong>{campaign.title}:</strong> {campaign.description}
								</Typography>
							</ListItem>
						))}
					</List>

					{/* Render each campaign slot */}
					{campaigns.map((campaign, index) => (
						<Accordion key={index} sx={{ mb: 2 }}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="h6">Campaign {index + 1}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth margin="normal">
											<InputLabel>K9 Version</InputLabel>
											<Select
												value={campaign.k9Version}
												label="K9 Version"
												onChange={(e) =>
													handleCampaignChange(
														index,
														"k9Version",
														e.target.value
													)
												}
											>
												<MenuItem value="">None</MenuItem>
												{k9CampaignOptions.map((option) => (
													<MenuItem key={option} value={option}>
														{option}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={6}>
										<FormControl fullWidth margin="normal">
											<InputLabel>Feline Version</InputLabel>
											<Select
												value={campaign.felVersion}
												label="Feline Version"
												onChange={(e) =>
													handleCampaignChange(
														index,
														"felVersion",
														e.target.value
													)
												}
											>
												<MenuItem value="">None</MenuItem>
												{felCampaignOptions.map((option) => (
													<MenuItem key={option} value={option}>
														{option}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={6}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												views={["year", "month"]}
												label="Launch Month"
												openTo="month"
												minDate={dayjs("2025-01-01")}
												maxDate={dayjs("2025-12-31")}
												value={campaign.launchMonth}
												onChange={(newValue) =>
													handleCampaignChange(index, "launchMonth", newValue)
												}
												renderInput={(params) => (
													<TextField {...params} fullWidth margin="normal" />
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item xs={12} md={6}>
										<FormControl component="fieldset" margin="normal">
											<FormLabel>Preferred half of the month</FormLabel>
											<RadioGroup
												row
												value={campaign.halfOfMonth}
												onChange={(e) =>
													handleCampaignChange(
														index,
														"halfOfMonth",
														e.target.value
													)
												}
											>
												<FormControlLabel
													value="1st"
													control={<Radio />}
													label="1st"
												/>
												<FormControlLabel
													value="2nd"
													control={<Radio />}
													label="2nd"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													checked={campaign.specialMessaging}
													onChange={(e) =>
														handleCampaignChange(
															index,
															"specialMessaging",
															e.target.checked
														)
													}
												/>
											}
											label="I have special messaging or a promo for this campaign; please contact me."
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											variant="outlined"
											color="secondary"
											onClick={() => handleRemoveCampaign(index)}
										>
											Remove Campaign
										</Button>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					))}

					{/* Button to add a new campaign, limited to 4 */}
					{campaigns.length < 4 && (
						<Box sx={{ textAlign: "left", mb: 2 }}>
							<Button variant="contained" onClick={handleAddCampaign}>
								Add Another Campaign
							</Button>
						</Box>
					)}
				</Box>

				{/* Submit */}
				<Box sx={{ textAlign: "center", mt: 2 }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</Box>
			</Container>
		</Box>
	);
}
