import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import { EasyEmailEditor, EmailEditor } from "../components/email-editor";
import CampaignTemplates from "../pages/dashboard/CampaignTemplates";
import CreateCampaign from "../pages/dashboard/Campaign";
import ImageUpload from "../layouts/dashboard/ImageUpload";
import CampaignStepper from "../pages/dashboard/CampaignStepper";
import ReRegisterCampaign from "../pages/dashboard/ReRegisterCampaign";
import CampaignRegistrationForm from "../pages/dashboard/CampaignRegistrationForm";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();
	const isDashboard = pathname.includes("/dashboard");

	return (
		<Suspense
			fallback={
				<LoadingScreen
					sx={{
						...(!isDashboard && {
							top: 0,
							left: 0,
							width: 1,
							zIndex: 9999,
							position: "fixed",
						}),
					}}
				/>
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	return useRoutes([
		{
			path: "auth",
			children: [
				{
					path: "login",
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{
					path: "register",
					element: (
						<GuestGuard>
							<Register />
						</GuestGuard>
					),
				},
				{ path: "login-unprotected", element: <Login /> },
				{ path: "register-unprotected", element: <Register /> },
				{ path: "reset-password", element: <ResetPassword /> },
				{ path: "verify", element: <VerifyCode /> },
			],
		},

		// Dashboard Routes
		{
			path: "dashboard",
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to="/dashboard/app" replace /> },
				{ path: "app", element: <GeneralApp /> },
				{ path: "reports", element: <ReportsGrid /> },
				{ path: "easyEmailEditor", element: <EasyEmailEditor /> },
                { path: "emailEditor/:templateId", element: <EasyEmailEditor /> },
				{ path: "campaigns", element: <CreateCampaign /> },
                { path: "campaigns/:campaignId", element: <CampaignStepper /> },
				{ path: "campaignTemplates", element: <CampaignTemplates /> },
				{ path: "gallery", element: <ImageUpload /> },
				{ path: "analytics", element: <GeneralAnalytics /> },
				{
					path: "user",
					children: [
						{ element: <Navigate to="/dashboard/user/profile" replace /> },
						{ path: "profile", element: <UserProfile /> },
						{ path: "cards", element: <UserCards /> },
						{ path: "list", element: <UserList /> },
						{ path: "new", element: <UserCreate /> },
						{ path: ":id/edit", element: <UserCreate /> },
						{ path: "account", element: <UserAccount /> },
					],
				},
				{
					path: "chat",
					children: [
						{ element: <Chat /> },
						{ path: "new", element: <Chat /> },
						{ path: ":conversationKey", element: <Chat /> },
					],
				},
				{ path: "calendar", element: <Calendar /> },
				{ path: "kanban", element: <Kanban /> },
			],
		},

		// Main Routes
		{
			path: "*",
			element: <LogoOnlyLayout />,
			children: [
				{ path: "coming-soon", element: <ComingSoon /> },
				{ path: "maintenance", element: <Maintenance /> },
				{ path: "500", element: <Page500 /> },
				{ path: "404", element: <NotFound /> },
				{ path: "*", element: <Navigate to="/404" replace /> },
                {
					path: "re-register-campaign",
					element: (
						<GuestGuard>
							<ReRegisterCampaign />
						</GuestGuard>
					),
				},
                {
					path: "register-campaign",
					element: (
						<GuestGuard>
							<CampaignRegistrationForm />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: "/",
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [{ element: <Navigate to="/dashboard/app" replace /> }],
		},
		{ path: "*", element: <Navigate to="/404" replace /> },
	]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
	lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
	lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
	lazy(() => import("../pages/authentication/VerifyCode"))
);
// Dashboard
const GeneralApp = Loadable(
	lazy(() => import("../pages/dashboard/GeneralApp"))
);
const ReportsGrid = Loadable(
	lazy(() => import("../pages/reports/ReportsGrid"))
);
const GeneralAnalytics = Loadable(
	lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const UserProfile = Loadable(
	lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
	lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
	lazy(() => import("../pages/dashboard/UserCreate"))
);
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));
// Main
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
