import React, { useState, useEffect } from "react";
import {
	Typography,
	Button,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Chip,
	IconButton,
	Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete"; // import Delete icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Page from "../../components/Page";
import { useNavigate } from "react-router";
import NewCampaignDialog from "./NewCampaignDialog";
import axios from "../../utils/axios";

const statusColorMap = {
	draft: "default",
	scheduled: "info",
	published: "success",
};

const statusLabelMap = {
	draft: "Draft",
	scheduled: "Scheduled",
	published: "Published",
};

export default function CreateCampaign() {
	const [campaigns, setCampaigns] = useState([]);
	const [open, setOpen] = useState(false);
	const [showStepper, setShowStepper] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [currentCampaignId, setCurrentCampaignId] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		fetchCampaigns().then((data) => setCampaigns(data));
	}, []);

	const fetchCampaigns = async () => {
		try {
			const response = await axios.get("campaign");
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error("Error fetching campaigns:", error);
			return [];
		}
	};

	const handleCreateNew = () => {
		handleDialogOpen();
	};

	const handleEditCampaign = (campaignId) => {
		setIsEditing(true);
		console.log(campaignId);
		handleCampaignSelection({ _id: campaignId });
	};

	const handleDialogOpen = () => {
		setOpen(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	const handleCampaignSelection = (campaignData) => {
		console.log(campaignData);
		navigate(`/dashboard/campaigns/${campaignData._id}`);
	};

	// 1. Function to handle deleting a campaign
	const handleDeleteCampaign = async (campaignId) => {
		// Optional: confirm the deletion
		const confirmDelete = window.confirm(
			"Are you sure you want to delete this campaign?"
		);
		if (!confirmDelete) return;

		try {
			await axios.delete(`campaign/${campaignId}`);
			// Update local state to remove the deleted campaign
			setCampaigns((prevCampaigns) =>
				prevCampaigns.filter((c) => c._id !== campaignId)
			);
		} catch (error) {
			console.error("Error deleting campaign:", error);
		}
	};

	return (
		<Page>
			{!showStepper && (
				<>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 3,
							p: 2,
							bgcolor: "background.paper",
							borderRadius: 1,
							boxShadow: 3,
						}}
					>
						<Typography variant="h4" component="h1">
							Campaigns
						</Typography>
						{/* 
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleCreateNew}
            >
              Create
            </Button> 
            */}
					</Box>
					<TableContainer
						component={Paper}
						sx={{
							mt: 2,
							p: 2,
							borderRadius: 1,
							boxShadow: 3,
							border: "1px solid #e0e0e0",
						}}
					>
						<Table>
							<TableHead>
								<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Status
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Type
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Created At
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Scheduled date
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
											Actions
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{campaigns.map((campaign) => (
									<TableRow
										key={campaign._id}
										hover
										sx={{
											"&:hover": {
												backgroundColor: "#f5f5f5",
											},
										}}
									>
										<TableCell component="th" scope="row">
											<Typography variant="body2">{campaign.name}</Typography>
										</TableCell>
										<TableCell>
											<Chip
												label={statusLabelMap[campaign.status.toLowerCase()]}
												color={statusColorMap[campaign.status.toLowerCase()]}
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<Typography variant="body2">{campaign.type}</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body2">
												{new Date(campaign.createdAt).toLocaleString()}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body2">
												{new Date(campaign.scheduledDate).toLocaleString()}
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Tooltip
												title={campaign.status === "Draft" ? "Edit" : "View"}
											>
												<IconButton
													color="primary"
													onClick={() => handleEditCampaign(campaign._id)}
												>
													{campaign.status === "Draft" ? (
														<EditIcon />
													) : (
														<VisibilityIcon />
													)}
												</IconButton>
											</Tooltip>
											{/* 2. Delete Icon Button */}
											<Tooltip title="Delete">
												<IconButton
													color="error"
													onClick={() => handleDeleteCampaign(campaign._id)}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			<NewCampaignDialog
				open={open}
				onOpen={handleDialogOpen}
				onClose={handleDialogClose}
				onContinue={handleCampaignSelection}
				initialValues={{
					name: "",
					scheduledDate: new Date(),
					campaignType: "",
					color: "#1890FF",
				}}
			/>
		</Page>
	);
}
